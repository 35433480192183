import React from 'react';
import classNames from "classnames";
import styles from "./video.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import { Button } from "../../../ui/Button";
import parse from 'html-react-parser';

const Video = (props) => {
    const { baseClass, moduleStyle, data } = props;

    return (
        <section
        className={classNames(baseClass, styles.main, {
          [styles.align_right]: data.alingment === "right",
          [styles.align_left]: data.alingment === "left",
        })}
        style={moduleStyle}
      >
     
          {data.title ? <h1 className={styles.title}>{data.title}</h1> : ""}
          {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}
          <div className={styles.body}>
          {data.body ? (
            <PortableText value={data.body} components={portableTextComponents} />
          ) : (
            ""
          )}
          </div>
         {data.buttonActive ?
          <div className={styles.button}>
            <Button data={data} />
          </div>
          : ""}
      

        <div className={styles.video}>
            {parse(data.video)}    
        </div>
        
        </section>
    );
};

export default Video;