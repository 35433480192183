import React, { useState } from 'react';
import styles from "./landing.module.scss";
import client from '../../../../config/client'
import imageUrlBuilder from '@sanity/image-url'
import { AnimatePresence, motion } from "framer-motion"

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const Column = (props) => {

    const { objects, startIndex, colNum } = props;
    const [ index, setIndex ] = useState(startIndex < objects.length ? startIndex : startIndex - objects.length);
    const [ duration, setDuration ] = useState(Math.random() * 4 + 5);
    const [ delay, setDelay ] = useState(Math.random() * 4 );
    const [ scale, setScale ] = useState(Math.random() * 0.1 + 1.4 );

    function onComplete() {
        setIndex((index + colNum * 2) % objects.length);
        setDuration(Math.random() * 4 + 5)
        setDelay(0)
        setScale(Math.random() * 0.1 + 1.4 )
      }

    return (
        <div className={styles.col}>
            <AnimatePresence key={index}>
                <motion.img 
                    initial={{ y: "-50vh", scale: scale * 0.6}}
                    animate={{ y: "100vh", scale: scale }}
                    transition={{ ease: "easeIn", duration: duration, delay: delay }}
                    className={styles.object} 
                    onAnimationComplete={onComplete}
                    src={urlFor(objects[index]).width(400).auto("format").url()} alt="" 
                />
            </AnimatePresence>
        </div>
    );
};

export default Column;