import React, { useState } from "react";
import styles from "./navigation.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";
import logo from "./logo_anim";

const Logo = (props) => {
  const { setNavExpanded, navExpanded, mainColor, secondaryColor } = props;
  const [player, setPlayer] = useState({ lottie: null });
  
  const handleMouseEnter = (event) => {
    player.lottie.playSegments(
        [0, 45],
        true
    );
  }

  const handleMouseLeave = (event) => {
    player.lottie.playSegments(
        [45, 62],
        true
    );
    }

  return (
    <Link
      className={styles.logo}
      onClick={() => {
        setNavExpanded(false);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to="/"
      aria-label="Go back to the fam home page"
      style={{
        "--main-color": navExpanded ? secondaryColor : mainColor,
        "--secondary-color": navExpanded ? mainColor : secondaryColor,
      }}
    >
      <Player
        lottieRef={(instance) => {
            setPlayer({ lottie: instance });
        }}
        src={logo(navExpanded ? mainColor : secondaryColor)}
        autoplay={false}
        loop={false}
        keepLastFrame={true}
        style={{ with: "100%", height: "100%" }}
      />
    </Link>
  );
};

export default Logo;
