import React from 'react';
import styles from "./button.module.scss";
import classNames from "classnames";

const FunctionButton = (props) => {
    const {clickFunction, text, label, styling, mainColor, secondaryColor, large} = props;

    return (
        <button 
            onClick={clickFunction}
            aria-label={label}
            className={
                classNames(styles.main, {
                    [styles.solid]: styling === "solid",
                    [styles.outline]: styling === "outline" || !styling,
                    [styles.large]: large
                })
            }
            
            style={{"--main-color" : mainColor, "--secondary-color" : secondaryColor}}
        >
            {text}
        </button>
    );
};

export default FunctionButton;