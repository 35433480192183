import React, { Fragment, useState, useEffect, useCallback } from "react";
import Footer from "../components/ui/Footer/Footer";
import { useParams } from "react-router-dom";
import Module from "../components/content/Module"
import client from "../config/client";
import Loading from "../components/ui/Loading/Loading";
import Metadata from "../components/content/Metadata";

const Page = (props) => {
  const slug = useParams();
  const {siteData} = props;
  const [pageData, setPageData] = useState(null);

  const fetchPageData = useCallback(async () => {
    let query;
    if (!slug.page) {
      query = `*[_id == "homePage"]`;
    } else if (!slug.post) {
      query = `*[_type == "page" && slug.current == "${slug.page}"]`;
    } else if (slug.post && slug.page === "what-we-do") {
      query = `*[_type == "caseStudy" && slug.current == "${slug.post}"]`;
    }

    client
      .fetch(query)
      .then((data) => {
        setPageData(data[0]);
      })
      .catch(console.error);
  }, [setPageData, slug.page, slug.post]);

  useEffect(() => {
    if (!pageData) {
      fetchPageData();
    }
  }, [pageData, fetchPageData]);

  if (!pageData) {
    return (
      <Loading />
    )
  }

  return (
    <Fragment>
      <main>
      {
        pageData?.content.map((module)=> (
            <Module data={module} key={module._key} />
        ))
      }  
      </main>
      <Footer data={siteData.footer}/>
      <Metadata 
        siteData={siteData}
        pageData={pageData}
      />
    </Fragment>
  );
};

export default Page;
