import React, {Fragment, useEffect, useCallback, useState, useRef} from 'react';
import client from '../../../config/client'
import classNames from 'classnames';
import styles from "./image.module.scss"
import { decode } from "blurhash";
import { AnimatePresence, motion } from 'framer-motion';


// Reduces the number of image requests at different sizes for sanity caching
const setWidth = (w) => {
    const standardWidths = [50, 100, 200, 415, 600, 834, 1440, 1920, 2500];

    for (let i = 0; i < standardWidths.length; i++) {

        if (i === 0 && w < standardWidths[0]) {
            return standardWidths[0];
        }

        if (w < standardWidths[i] && w > standardWidths[i -1]) {
            return standardWidths[i];
        }
        
        if (i === standardWidths.length - 1) {
            return standardWidths[i];
        }
        

    }
}


const ImageLoader = (props) => {

    const {data, crop} = props;
    const [url, setUrl] = useState(null);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isBlurhashLoaded, setIsBlurhashLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const image = useRef();
    const canvasRef = useRef();

    const createCanvas = useCallback((imageData, height) => {

        const canvas = canvasRef.current;
        if (height) {
            canvas.style.height = height;
        }
        const ctx = canvas.getContext("2d");

        // Uses blurhash for images without transparent elements
        // Blurhash doesn't support transparency and replaces with black
        // Blurhash always has a length of 44, so if lqip is brought instead use other method 
        if (imageData.length <= 44) {
            const pixels = decode(imageData, ctx.canvas.width, ctx.canvas.height);
            const blurImageData = ctx.createImageData(ctx.canvas.width, ctx.canvas.height);
            blurImageData.data.set(pixels);
            ctx.putImageData(blurImageData, 0, 0);
        } else {
            canvas.style.background = "none";
            canvas.style.filter = "blur(20px)";
            const blurImageData = new Image();
            blurImageData.src = imageData;
            ctx.drawImage(blurImageData, 0, 0, ctx.canvas.width, ctx.canvas.height);    
        }

       
    }, [canvasRef])

    const createImage = useCallback( (imageData, size) => {

        let imageUrl = imageData.url;
        imageUrl += `?w=${size.width}`;
        if (crop) {
            imageUrl += `&h=${size.height}`;
            imageUrl += `&crop=focalpoint`;
            imageUrl += `&fit=crop`;
        }
        imageUrl += `&dpr=${window.devicePixelRatio}`;
        imageUrl += "&auto=format"

        setUrl(imageUrl);
    }, [setUrl, crop])

    const fetchBlurHash = useCallback( async () => {
       const query = `*[_id == "${data.asset._ref}"]{"placeholder": select(
        metadata.isOpaque == true => metadata.blurHash,
        metadata.isOpaque == false => metadata.lqip
      ), "apectRatio" : metadata.dimensions.aspectRatio}`;
       client
        .fetch(query)
        .then((response) => {
            let height = null;
            if (!crop) {
                height = canvasRef.current.clientWidth * response[0].aspectRatio
           }
            createCanvas(response[0].placeholder, height);
            setIsBlurhashLoaded(true);
        }) 
    }, [data, createCanvas, crop])

    const fetchImage = useCallback( async (size) => {
       const query = `*[_id == "${data.asset._ref}"]`;
       client
        .fetch(query)
        .then((response) => {
            createImage(response[0], size);
        }) 
    }, [createImage, data])

    const handleLoad = () => {
        if (url) {
            setIsImageLoaded(true)
        }
    }

    const handleInView = (entries) => {
       const [ entry] = entries;

       if (entry.isIntersecting) {
            setIsVisible(true);
       }
    }

   

    useEffect(() => {
        const imageObject = image.current;
        const observer = new IntersectionObserver(handleInView, {root: null, rootMargin: "0px", threshold: 0.1})

        if (imageObject) {

            if (!isVisible) {
                observer.observe(imageObject);
            }
        
            if (!url) {

                    if (!isBlurhashLoaded && canvasRef.current) {
                        fetchBlurHash();
                    }
                    
                    const width = imageObject.parentNode.clientWidth;
                    const ratio = imageObject.parentNode.clientWidth / image.current.parentNode.clientHeight

                    const size ={
                        width: setWidth(width),
                        height: crop ? Math.ceil(setWidth(width) / ratio) : 1,
                    }

                    if (isVisible) {
                        fetchImage(size);
                    }
                
            }
        }
        

        return () => {
            if (imageObject) {
                observer.unobserve(imageObject);
            }
        }
        
    }, [url, setUrl, fetchImage, crop, fetchBlurHash, isVisible, isBlurhashLoaded])


    return (
        <Fragment>
            
            <motion.img 
                className={classNames(styles.image, {[styles.uncropped] : !crop})} 
                alt="" src={url} ref={image} onLoad={handleLoad}
                animate={{opacity: isImageLoaded ? 1 : 0 }}
                transition={{ ease: "easeIn", duration: 0.1 }}
                />
            <AnimatePresence>
                {!isImageLoaded && (
                    <motion.canvas 
                        transition={{ ease: "easeIn", duration: 0.2, delay: 0.1 }}
                        animate={{opacity: 1}}
                        exit={{opacity: 0, position: "absoulte"}}
                        className={classNames(styles.canvas, {[styles.uncropped] : !crop})}
                        ref={canvasRef}
                    />
                )}
            </AnimatePresence>
            
         </Fragment>
    );
};

export default ImageLoader;