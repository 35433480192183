import React from 'react';
import styles from "./module.module.scss";
import { Animation, Image, Hero, CaseStudyDirectory, ProjectInfo, ThemeCards, Video, Carousel, Landing, Logos, Team, TwoColumnText, ThreeColumns, Newsletter, AnimationCards } from "./modules"

const Module = (props) => {

    const {data} = props;

    const moduleStyle = {
        "--background-color": data.backgroundColor ? data.backgroundColor : "inherit", 
        "--text-color": data.bodyColor ? data.bodyColor : "inherit",
        "--highlight-color": data.highlightColor ? data.highlightColor : "inherit",
    }
    const baseClass = styles.main;

    
    switch(data._type) {
        case "animation_module":
            return <Animation  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "image_module":
            return <Image  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "hero_module":
            return <Hero  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "caseStudyDirectory_module":
            return <CaseStudyDirectory  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "projectInfo_module":
            return <ProjectInfo  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "themeCards_module":
            return <ThemeCards  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "video_module":
            return <Video  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "carousel_module":
            return <Carousel baseClass={baseClass} data={data} />;
        case "landingScreen_module":
            return <Landing  baseClass={baseClass} data={data} />;
        case "logos_module":
            return <Logos  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "team_module":
            return <Team  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "twoColumnText_module":
            return <TwoColumnText  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "threeColumns_module":
            return <ThreeColumns  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "newsletter_module":
            return <Newsletter  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        case "animationCards_module":
            return <AnimationCards  moduleStyle={moduleStyle} baseClass={baseClass} data={data} />;
        default:
            return null;
    }

};

export default Module;