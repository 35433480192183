import React from "react";
import classNames from "classnames";
import styles from "./projectInfo.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";

const ProjectInfo = (props) => {
  const { baseClass, moduleStyle, data } = props;

  return (
    <section
      className={classNames(baseClass, styles.main)}
      style={moduleStyle}
    >

      {data.title ? <h1 className={styles.title}>{data.title}</h1> : ""}
      {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}

      <div className={styles.body}>
        {data.body ? (
          <PortableText value={data.body} components={portableTextComponents} />
        ) : (
          ""
        )}
      </div>
      <div className={styles.info}>
        {data.projectType ? (
          <p>
            project type: <br /> {data.projectType}{" "}
          </p>
        ) : (
          ""
        )}
        {data.partners ? (
          <p>
            project type: <br /> {data.partners}{" "}
          </p>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default ProjectInfo;
