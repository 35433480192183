import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import styles from "./landing.module.scss";
import LottieAnimation from "../../LottieAnimation/LottieAnimation";
import useWindowSize from "../../../../hooks/useWindowSize";
import Layer from "./Layer"

const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }


    return array;
}

const Landing = (props) => {

    const { data, baseClass } = props;
    const sizes = useWindowSize();
    const [objects, setObjects] = useState(null);

   
    useEffect(() => {
        if (!objects) { 
            setObjects(shuffle(data.objects));
        }
    },[objects, setObjects, data.objects])

    return (
        <section    
            className={classNames(baseClass, styles.main)}
        >
            <Layer objects={objects} />
            <div className={styles.animation}>
                <LottieAnimation
                animation={
                   sizes.width < 415
                    ? data.logo.mobile
                    : data.logo.desktop
                }
                animationMode={"play"}
                />
            </div>
            <Layer objects={objects} isBackground/>
        </section>
    );
};

export default Landing;