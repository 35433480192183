import React from "react"
import {Helmet} from "react-helmet"
import { useParams } from "react-router-dom";
import client from '../../config/client'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const Metadata = (props) => {
    const { pageData, siteData } = props;

    const slug = useParams();

    return (
        <Helmet>
            <title>{`${pageData?.title !== "Home" ? `${pageData?.title} | ` : ""} fam | the family invention studio`}</title>
            <meta name="title" content={`${pageData?.title !== "Home" ? `${pageData?.title} | ` : ""} fam | the family invention studio}`}/>
            <meta name="description" content={pageData.description ? pageData.description : siteData.meta.description}/>

            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`https://famstudio.co/${slug.page ? slug.page : ""}${slug.post ? `/${slug.post}` : ""}`}/>
            <meta property="og:title" content={`${pageData?.title !== "Home" ? `${pageData?.title} | ` : ""} fam | the family invention studio`}/>
            <meta property="og:description" content={pageData.description ? pageData.description : siteData.meta.description}/>
            <meta property="og:image" content={pageData.socialImage ? urlFor(pageData.socialImage).width(1200).height(627) : urlFor(siteData.meta.socialImage).width(1200).height(627)}/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={`https://famstudio.co/${slug.page ? slug.page : ""}${slug.post ? `/${slug.post}` : ""}`}/>
            <meta property="twitter:title" content={`${pageData?.title !== "Home" ? `${pageData?.title} | ` : ""} fam | the family invention studio`}/>
            <meta property="twitter:description" content={pageData.description ? pageData.description : siteData.meta.description}/>
            <meta property="twitter:image" content={pageData.socialImage ? urlFor(pageData.socialImage).width(1200).height(627) : urlFor(siteData.meta.socialImage).width(1200).height(627)}/>
        </Helmet>
    )
}

export default Metadata