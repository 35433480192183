import React, { Fragment, useState, useEffect, useCallback } from "react";
import Page from "./pages/Page";
import client from "./config/client"
import { NavBar } from "./components/ui/Navigation";

import { Routes, Route, useLocation } from "react-router-dom";
import "./styles/globals.scss"
import Loading from "./components/ui/Loading/Loading";

function App() {
  const location = useLocation();
  const [siteData, setSiteData] = useState(null);

  const fetchSiteData = useCallback(async () => {
    const query = `
    *[_id == "settings"][0] {
      "nav": navBarPages[] {
        _type,
        title,
        url,
        page
      },
      "footer": {
        copyright,
        lists[] {
          ...,
          links[] {
             ...,
             page-> {
               title,
               slug
             }
          }
        }
      },
      "meta": {
        title,
        description,
        socialImage,
        companyLogo
      }
    }`
      client
    .fetch(query)
    .then((data) => {
      setSiteData(data);
    })
  .catch(console.error);
  }, [])

  useEffect(() => {
    if (!siteData) {
      fetchSiteData();
    }
  })

  if (!siteData) { return <Loading />}

  return (
    <Fragment>
      <NavBar linksData={siteData.nav}/>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={
          <Page siteData={siteData}/>
        } />
        <Route path="/:page" element={
          <Page siteData={siteData}/>
        } />
        <Route path="/:page/:post" element={
          <Page siteData={siteData}/>
        } />
      </Routes>
    </Fragment>
  );
}

export default App;
