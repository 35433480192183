import React from "react";
import classNames from "classnames";
import styles from "./twoColumnText.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import { Button } from "../../../ui/Button";
import ImageLoader from "../../ImageLoader/ImageLoader";
import useWindowSize from "../../../../hooks/useWindowSize";

const TwoColumnText = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();

  return (
    <section className={classNames(baseClass, styles.main, {[styles.display_fill] : data.useBackgroundImage && data.image})} style={moduleStyle}>

      <h1 className={styles.title}>{data.title ? data.title : ""}</h1> 
      {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}

      
        <div className={styles.body}>
          {data.col1 ? (
            <PortableText
              value={data.col1}
              components={portableTextComponents}
            />
          ) : (
            ""
          )}
          {data.buttonActive ? (
            <div className={styles.button}>
              <Button data={data} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={styles.body}>
          {data.col2 ? (
            <PortableText
              value={data.col2}
              components={portableTextComponents}
            />
          ) : (
            ""
          )}
        </div>
  
      {data.useBackgroundImage && data.image ? (
        <div className={styles.image}>
          <ImageLoader
            data={data.image}
            crop={true}
            width={sizes.width}
            aspectRatio={16 / 9}
          />
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default TwoColumnText;
