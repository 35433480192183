
const logo = (color) => {

    color = (typeof color !== 'undefined') ? color : "#FF4927";

    const  hexToRgbA = (hex) => {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return [((c>>16)&255) / 255, ((c>>8)&255) / 255 , (c&255) / 255,1];
        }
        throw new Error('Bad Hex');
    }
    
    const rgba = hexToRgbA(color);
    

    return `
    {
        "v": "5.7.4",
        "fr": 25,
        "ip": 0,
        "op": 63,
        "w": 384,
        "h": 177,
        "nm": "logo 2",
        "ddd": 0,
        "assets": [],
        "layers": [
          {
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "m",
            "sr": 1,
            "ks": {
              "o": { "a": 0, "k": 100, "ix": 11 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -68,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.666], "y": [1.011] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -54,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.428], "y": [0.031] },
                    "t": -47,
                    "s": [56.142]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -43,
                    "s": [41]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -38,
                    "s": [46]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -33,
                    "s": [33]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -30,
                    "s": [34]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -27,
                    "s": [33]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 0,
                    "s": [33]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 11,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 15,
                    "s": [6]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 19,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 23,
                    "s": [3]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 27,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 31,
                    "s": [1]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 35,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 50,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63,
                    "s": [33]
                  },
                  { "t": 64, "s": [0] }
                ],
                "ix": 10
              },
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": -68,
                    "s": [-123.107, 168.02, 0],
                    "to": [71.833, 0, 0],
                    "ti": [-71.833, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": -54,
                    "s": [307.893, 168.02, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 0,
                    "s": [307.893, 168.02, 0],
                    "to": [2.409, -0.38, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 11,
                    "s": [365.893, 168.02, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.167, "y": 0 },
                    "t": 50,
                    "s": [365.893, 168.02, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 63,
                    "s": [307.893, 168.02, 0],
                    "to": [2.409, -0.38, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.167, "y": 0 },
                    "t": 64,
                    "s": [365.893, 168.02, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.167, "y": 0 },
                    "t": 68,
                    "s": [365.893, 153.02, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  { "t": 72, "s": [365.893, 168.02, 0] }
                ],
                "ix": 2,
                "l": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 0,
                    "s": [306.714, 143.619, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 50,
                    "s": [306.714, 143.619, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 63,
                    "s": [306.714, 143.619, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  { "t": 64, "s": [306.714, 143.619, 0] }
                ],
                "ix": 1,
                "l": 2
              },
              "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
            },
            "ao": 0,
            "shapes": [
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [-4.157, -8.468],
                          [-0.004, -13.908],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [1.571, 4.703],
                          [5.323, 0],
                          [4.639, -6.683],
                          [-0.004, -20.437],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [1.571, 4.703],
                          [5.315, -0.008],
                          [4.634, -6.683],
                          [0, -20.437],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-13.366, 0],
                          [-4.631, -4.226],
                          [-1.781, -7.234],
                          [-5.392, 3.886],
                          [-7.221, 0.016]
                        ],
                        "o": [
                          [4.157, 8.456],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, -10.091],
                          [-1.57, -4.704],
                          [-5.59, 0],
                          [-4.638, 6.683],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, -10.091],
                          [-1.57, -4.704],
                          [-5.594, 0],
                          [-4.635, 6.683],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [6.545, -12.843],
                          [8.727, 0],
                          [4.631, 4.226],
                          [3.271, -7.897],
                          [5.391, -3.886],
                          [12.823, 0]
                        ],
                        "v": [
                          [68.627, -39.262],
                          [74.869, -5.716],
                          [74.869, 51.964],
                          [48.275, 51.964],
                          [48.275, -5.716],
                          [45.919, -27.908],
                          [35.579, -34.963],
                          [20.236, -24.939],
                          [13.284, 15.741],
                          [13.284, 51.952],
                          [-13.297, 51.952],
                          [-13.297, -5.729],
                          [-15.653, -27.92],
                          [-25.981, -34.963],
                          [-41.323, -24.939],
                          [-48.275, 15.741],
                          [-48.275, 51.952],
                          [-74.869, 51.952],
                          [-74.869, -50.306],
                          [-48.275, -50.306],
                          [-48.275, -32.699],
                          [-18.409, -51.964],
                          [1.627, -45.625],
                          [11.244, -28.436],
                          [24.238, -46.111],
                          [43.157, -51.964]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [${rgba}],
                      "ix": 4
                    },
                    "o": { "a": 0, "k": 100, "ix": 5 },
                    "r": 1,
                    "bm": 0,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": { "a": 0, "k": [233.643, 92.286], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100], "ix": 3 },
                    "r": { "a": 0, "k": 0, "ix": 6 },
                    "o": { "a": 0, "k": 100, "ix": 7 },
                    "sk": { "a": 0, "k": 0, "ix": 4 },
                    "sa": { "a": 0, "k": 0, "ix": 5 },
                    "nm": "Transform"
                  }
                ],
                "nm": "Group 1",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
              }
            ],
            "ip": 0,
            "op": 63,
            "st": -75,
            "bm": 0
          },
          {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "a",
            "sr": 1,
            "ks": {
              "o": { "a": 0, "k": 100, "ix": 11 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -49,
                    "s": [-0.388]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -43,
                    "s": [8.612]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -39,
                    "s": [-7.388]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -36,
                    "s": [-3.388]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -33,
                    "s": [-7.388]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 2,
                    "s": [-7.388]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 11,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 50,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63,
                    "s": [-7.388]
                  },
                  { "t": 64, "s": [0] }
                ],
                "ix": 10
              },
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": -68,
                    "s": [-123.164, 162.782, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": -58,
                    "s": [-123.164, 162.782, 0],
                    "to": [52.167, -0.25, 0],
                    "ti": [-52.167, 0.25, 0]
                  },
                  {
                    "i": { "x": 0.654, "y": 0.654 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": -49,
                    "s": [189.836, 161.282, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.673, "y": 0.673 },
                    "o": { "x": 0.339, "y": 0.339 },
                    "t": 0,
                    "s": [189.836, 161.282, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 2,
                    "s": [189.836, 161.282, 0],
                    "to": [3.167, 0.667, 0],
                    "ti": [-3.167, -0.667, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 11,
                    "s": [208.836, 165.282, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 50,
                    "s": [208.836, 165.282, 0],
                    "to": [-3.167, -0.667, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 63,
                    "s": [189.836, 161.282, 0],
                    "to": [0, 0, 0],
                    "ti": [-3.167, -0.667, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 64,
                    "s": [208.836, 165.282, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 66,
                    "s": [208.836, 165.282, 0],
                    "to": [0, -2.5, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 70,
                    "s": [208.836, 150.282, 0],
                    "to": [0, 0, 0],
                    "ti": [0, -2.5, 0]
                  },
                  { "t": 74, "s": [208.836, 165.282, 0] }
                ],
                "ix": 2,
                "l": 2
              },
              "a": { "a": 0, "k": [150.067, 141.147, 0], "ix": 1, "l": 2 },
              "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
            },
            "ao": 0,
            "shapes": [
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [-3.056, -2.526],
                          [-4.618, 0],
                          [-3.805, 2.482],
                          [-2.724, 5.453],
                          [-0.036, 8.043],
                          [0, 0],
                          [2.37, -0.431],
                          [4.222, -0.542],
                          [4.527, -1.218],
                          [2.402, -2.625],
                          [0, -5.031]
                        ],
                        "o": [
                          [3.072, 2.522],
                          [4.543, -0.056],
                          [4.157, -2.595],
                          [2.725, -5.452],
                          [0, 0],
                          [-2.013, 1.323],
                          [-2.659, 0.546],
                          [-4.663, 0.484],
                          [-3.419, 0.988],
                          [-2.452, 2.66],
                          [-0.008, 4.234]
                        ],
                        "v": [
                          [-17.099, 33.54],
                          [-5.564, 37.323],
                          [7.217, 33.437],
                          [17.539, 21.366],
                          [21.68, 1.124],
                          [21.68, -0.51],
                          [15.038, 2.15],
                          [4.715, 3.783],
                          [-9.091, 6.339],
                          [-17.992, 11.864],
                          [-21.672, 23.4]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ind": 1,
                    "ty": "sh",
                    "ix": 2,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [-8.791, -6.206],
                          [0.004, -14.316],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [6.856, -2.999],
                          [7.268, -0.038],
                          [5.93, 4.979],
                          [0.004, 9.419],
                          [-4.501, 4.57],
                          [-6.079, 1.328],
                          [-9.682, 0.818],
                          [-3.61, 1.024],
                          [0, 3.408],
                          [3.477, 2.186],
                          [4.91, -0.004],
                          [4.401, -2.363],
                          [1.231, -7.092],
                          [0, 0],
                          [-9.087, 5.096],
                          [-11.435, 0.016]
                        ],
                        "o": [
                          [8.8, 6.205],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-4.855, 5.695],
                          [-6.622, 2.995],
                          [-8.865, 0],
                          [-5.93, -4.978],
                          [0, -8.865],
                          [4.326, -4.473],
                          [6.137, -1.364],
                          [8.46, -0.684],
                          [3.611, -1.024],
                          [0, -5.586],
                          [-3.477, -2.185],
                          [-4.995, -0.012],
                          [-4.497, 2.384],
                          [0, 0],
                          [1.619, -10.929],
                          [9.087, -5.096],
                          [12.823, 0]
                        ],
                        "v": [
                          [35.079, -43.169],
                          [48.273, -12.387],
                          [48.273, 50.413],
                          [21.68, 50.413],
                          [21.68, 34.663],
                          [3.883, 47.875],
                          [-17.185, 52.477],
                          [-39.376, 45.009],
                          [-48.277, 23.413],
                          [-41.525, 3.261],
                          [-25.569, -5.635],
                          [-1.841, -8.908],
                          [16.263, -11.469],
                          [21.68, -18.118],
                          [16.465, -29.775],
                          [3.883, -33.048],
                          [-10.432, -29.465],
                          [-19.025, -15.252],
                          [-44.184, -20.771],
                          [-28.125, -44.809],
                          [2.658, -52.477]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Path 2",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "mm",
                    "mm": 1,
                    "nm": "Merge Paths 1",
                    "mn": "ADBE Vector Filter - Merge",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [${rgba}],
                      "ix": 4
                    },
                    "o": { "a": 0, "k": 100, "ix": 5 },
                    "r": 1,
                    "bm": 0,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": { "a": 0, "k": [105.388, 93.843], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100], "ix": 3 },
                    "r": { "a": 0, "k": 0, "ix": 6 },
                    "o": { "a": 0, "k": 100, "ix": 7 },
                    "sk": { "a": 0, "k": 0, "ix": 4 },
                    "sa": { "a": 0, "k": 0, "ix": 5 },
                    "nm": "Transform"
                  }
                ],
                "nm": "Group 2",
                "np": 4,
                "cix": 2,
                "bm": 0,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
              }
            ],
            "ip": 0,
            "op": 63,
            "st": -75,
            "bm": 0
          },
          {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "f",
            "sr": 1,
            "ks": {
              "o": { "a": 0, "k": 100, "ix": 11 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": -36,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -30,
                    "s": [14]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -26,
                    "s": [7]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -22,
                    "s": [14]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": -19,
                    "s": [13]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": -16,
                    "s": [14]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": -13,
                    "s": [13]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": -10,
                    "s": [14]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 0,
                    "s": [14]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 9,
                    "s": [19]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 13,
                    "s": [29]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 19,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 26,
                    "s": [-10]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 31,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.833], "y": [0.833] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 36,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 41,
                    "s": [4]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 45,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.167], "y": [0] },
                    "t": 50,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63,
                    "s": [14]
                  },
                  { "t": 64, "s": [0] }
                ],
                "ix": 10
              },
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": -68,
                    "s": [-152.055, 162.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": -47,
                    "s": [-152.055, 162.757, 0],
                    "to": [35.25, 0, 0],
                    "ti": [-35.25, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": -36,
                    "s": [59.445, 162.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 0,
                    "s": [59.445, 162.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 18,
                    "s": [59.445, 162.757, 0],
                    "to": [-4.5, 0, 0],
                    "ti": [4.5, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 19,
                    "s": [32.445, 162.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0 },
                    "t": 31,
                    "s": [32.445, 162.757, 0],
                    "to": [6.667, 0.5, 0],
                    "ti": [-11.167, -0.5, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 35,
                    "s": [72.445, 165.757, 0],
                    "to": [11.167, 0.5, 0],
                    "ti": [-4.5, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 36,
                    "s": [99.445, 165.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 50,
                    "s": [99.445, 165.757, 0],
                    "to": [-6.667, -0.5, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 63,
                    "s": [59.445, 162.757, 0],
                    "to": [0, 0, 0],
                    "ti": [-6.667, -0.5, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 64,
                    "s": [99.445, 165.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 65,
                    "s": [99.445, 165.757, 0],
                    "to": [0, -3, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 69,
                    "s": [99.445, 147.757, 0],
                    "to": [0, 0, 0],
                    "ti": [0, -3, 0]
                  },
                  { "t": 73, "s": [99.445, 165.757, 0] }
                ],
                "ix": 2,
                "l": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 18,
                    "s": [43.113, 142.253, 0],
                    "to": [-4.5, 0, 0],
                    "ti": [4.5, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 19,
                    "s": [16.113, 142.253, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 35,
                    "s": [16.113, 142.253, 0],
                    "to": [4.5, 0, 0],
                    "ti": [-4.5, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 36,
                    "s": [43.113, 142.253, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 50,
                    "s": [43.113, 142.253, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  {
                    "i": { "x": 0.833, "y": 0.833 },
                    "o": { "x": 0.167, "y": 0.167 },
                    "t": 63,
                    "s": [43.113, 142.253, 0],
                    "to": [0, 0, 0],
                    "ti": [0, 0, 0]
                  },
                  { "t": 64, "s": [43.113, 142.253, 0] }
                ],
                "ix": 1,
                "l": 2
              },
              "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
            },
            "ao": 0,
            "shapes": [
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-2.724, 5.185],
                          [-6.278, 2.449],
                          [-10.771, 0],
                          [0, 0],
                          [2.319, -2.049],
                          [0, -5.586],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, -8.861],
                          [2.724, -5.185],
                          [6.274, -2.457],
                          [0, 0],
                          [-6.407, 0],
                          [-2.319, 2.048],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [12.681, -10.231],
                          [12.681, 72.003],
                          [-13.907, 72.003],
                          [-13.907, -10.231],
                          [-29.864, -10.231],
                          [-29.864, -31.482],
                          [-13.907, -31.482],
                          [-13.907, -35.798],
                          [-9.821, -56.867],
                          [3.683, -68.318],
                          [29.25, -72.003],
                          [29.25, -50.322],
                          [16.16, -47.249],
                          [12.681, -35.798],
                          [12.681, -31.5],
                          [29.864, -31.5],
                          [29.864, -10.249]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Path 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [${rgba}],
                      "ix": 4
                    },
                    "o": { "a": 0, "k": 100, "ix": 5 },
                    "r": 1,
                    "bm": 0,
                    "nm": "Fill 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": { "a": 0, "k": [30.113, 72.253], "ix": 2 },
                    "a": { "a": 0, "k": [0, 0], "ix": 1 },
                    "s": { "a": 0, "k": [100, 100], "ix": 3 },
                    "r": { "a": 0, "k": 0, "ix": 6 },
                    "o": { "a": 0, "k": 100, "ix": 7 },
                    "sk": { "a": 0, "k": 0, "ix": 4 },
                    "sa": { "a": 0, "k": 0, "ix": 5 },
                    "nm": "Transform"
                  }
                ],
                "nm": "Group 3",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
              }
            ],
            "ip": 0,
            "op": 63,
            "st": -75,
            "bm": 0
          }
        ],
        "markers": []
      }
    `;

}

export default logo