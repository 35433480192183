import React, {useState, useCallback, useEffect} from "react";
import classNames from "classnames";
import styles from "./caseStudyDirectory.module.scss";
import ImageLoader from "../../ImageLoader/ImageLoader";
import client from "../../../../config/client"
import useWindowSize from "../../../../hooks/useWindowSize";
import { Link } from 'react-router-dom';

const CaseStudyCard = (props) => {

    const { data } = props;
    const sizes = useWindowSize();

    const [cardData, setCardData] = useState(null)
    
    const getCardData = useCallback(async () => {
        const query = `*[_id == "${data._ref}"][0]{title, slug, cover, tagline}`;
    
        client
        .fetch(query)
        .then((data) => {
            setCardData(data)
        })
        .catch(console.error);
    }, [setCardData, data._ref])
    
    useEffect(() => {
        if (!cardData && data._ref) {
            getCardData();
        }
    }, [cardData, getCardData, data._ref])

    if (cardData) {
        return (
            <Link to={cardData.slug.current} className={classNames(styles.card)}>
                <div className={styles.image}>
                    <ImageLoader data={cardData.cover} crop width={sizes.width / 3} aspectRatio={16 / 9} />
                </div>
                <h4>{cardData.title}</h4>
                <p>{cardData.tagline}</p>
            </Link>
        );
    }

    return null;
};

export default CaseStudyCard;