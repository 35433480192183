import React from "react";
import classNames from "classnames";
import styles from "./hero.module.scss";
import ImageLoader from "../../ImageLoader/ImageLoader";
import useWindowSize from "../../../../hooks/useWindowSize";

const Hero = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();

  return (
    <section
      className={classNames(baseClass, styles.main)}
      style={moduleStyle}
    >
        <div className={styles.text}>
            {data.title ? <h1>{data.title}</h1> : ""}
            {data.heading ? <h2>{data.heading}</h2> : ""}
        </div>
        <div
            className={styles.container}
        >
            <ImageLoader data={data.image} crop width={sizes.width} aspectRatio={sizes.width < 410 ? 9 / 16 : 16 / 9} />
        </div>
    </section>
  );
};

export default Hero;
