import sanityClient from "@sanity/client";

const options = {
  projectId: "6z87gl6e", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  apiVersion: '2022-07-08',
  useCdn: true
}

const client = sanityClient(options)


export const previewClient = sanityClient({
  projectId: "6z87gl6e", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  apiVersion: '2022-07-08',
  token: '',
  useCdn: false,
});

export default client;