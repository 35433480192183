import React from "react";
import classNames from "classnames";
import styles from "./caseStudyDirectory.module.scss";
import CaseStudyCard from "./CaseStudyCard";

const CaseStudyDirectory = (props) => {
  const { baseClass, moduleStyle, data } = props;

  return (
    <section className={classNames(baseClass, styles.main)} style={moduleStyle}>
      <div className={styles.text}>
        {data.title ? <h1>{data.title}</h1> : ""}
        {data.heading ? <h2>{data.heading}</h2> : ""}
      </div>
        {data.list.map((card, index) => (
          <CaseStudyCard key={index} data={card} />
        ))}
    </section>
  );
};

export default CaseStudyDirectory;
