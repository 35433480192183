import {Link} from "react-router-dom"

const portableTextComponents = {
    marks: {
        link: ({children, value}) => {
            return (
              <a href={value.href} target="_blank" rel="noreferrer noopener">
                {children}
              </a>
            )
          },
        internalLink: ({children, value}) => {
          return (
            <Link to={`/${value.slug.current}`} >
              {children}
            </Link>
          )
        },
    },
    block: {
      h1: ({children}) =>  <h1 >{children}</h1>,
      h2: ({children}) =>  <h2 >{children}</h2>,
      h3: ({children}) =>  <h3 >{children}</h3>,
      p: ({children}) =>  <p >{children}</p>,
      span: ({children}) =>  <span >{children}</span>,
      normal: ({children}) =>  <p >{children}</p>
    }
}

export default portableTextComponents