import React from 'react';
import classNames from "classnames";
import styles from "./themeCards.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import Card from './Card';

const ThemeCards = (props) => {
    const { baseClass, moduleStyle, data } = props;

    return (
        <section
      className={classNames(
        baseClass,
        styles.main,
      )}
      style={moduleStyle}
    >
  
        {data.title ? <h1 className={styles.title}>{data.title}</h1> : ""}
        {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}
        <div  className={styles.body}>
        {data.body ? (
          <PortableText value={data.body} components={portableTextComponents}/>
        ) : (
          ""
        )}
        </div>

        {data.cards.map((card, index) => (
            <Card data={card} key={index} />
        ))}

        </section>
    );
};

export default ThemeCards;