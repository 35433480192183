import React from 'react';
import styles from "./button.module.scss";
import classNames from "classnames";


const EmailButton = (props) => {

    const {email, text, label, styling, mainColor, secondaryColor, clickFunction, large} = props;

    if (!email) {return null}


    return (
        <a 
            href={`mailto:${email}`}
            // target="_blank"
            rel="noreferrer"
            onClick={clickFunction}
            aria-label={label}
            className={
                classNames(styles.main, {
                    [styles.solid]: styling === "solid",
                    [styles.outline]: styling === "outline" || !styling,
                    [styles.large]: large
                })
            }
            
            style={{"--main-color" : mainColor, "--secondary-color" : secondaryColor}}
        >
            {text}
        </a>
    );
};

export default EmailButton;