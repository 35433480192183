import React from "react"
import classNames from "classnames";
import styles from "./animationCards.module.scss";
import Card from "./Card";

const AnimationCards = (props) => {
  const { baseClass, moduleStyle, data } = props;

    return (
        <section style={moduleStyle} className={classNames(baseClass, styles.main)}>
            {
                data.cards?.map((card, index) => (
                    <Card data={card} key={index} />
                ))
            }
        </section>
    )
}

export default AnimationCards