import React from "react";
import classNames from "classnames";
import styles from "./animation.module.scss";
import LottieAnimation from "../../LottieAnimation/LottieAnimation";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import { Button } from "../../../ui/Button";
import useWindowSize from "../../../../hooks/useWindowSize";

const Animation = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();

  return (
    <section
      className={classNames(baseClass, styles.main, {
        [styles.align_right]: data.alingment === "right",
        [styles.align_center]: data.alingment === "center",
        [styles.align_left]: data.alingment === "left",
        [styles.align_text]: data.alingment === "textFocus",
      })}
      style={moduleStyle}
    >
      {data.title ? <h1 className={styles.title}>{data.title}</h1> : ""}
      {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}
      <div className={styles.body}>
        {data.body ? (
          <PortableText
            value={data.body}
            components={portableTextComponents}
            className={styles.body}
          />
        ) : (
          ""
        )}
      </div>
      {data.buttonActive ? (
        <div className={styles.button}>
          <Button data={data} />
        </div>
      ) : (
        ""
      )}

      <div className={styles.animation}>
        <LottieAnimation
          animation={
            data.useMobileAnimation && data.mobileAnimation && sizes.width < 415
              ? data.mobileAnimation
              : data.animation
          }
          animationMode={data.animationMode}
        />
      </div>
    </section>
  );
};

export default Animation;
