import React, { useState } from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
import useWindowSize  from '../../../hooks/useWindowSize';
import useScrollPosition from '../../../hooks/useScrollPosition';


const LottieAnimation = (props) => {
    
    const {animation, animationMode} = props;
    const [player, setPlayer] = useState({ lottie: null});
    const [played, setPlayed] = useState(animationMode === "loop" || animationMode === "play");
    const [scrollPoint, setScrollPoint] = useState(null);
    const size = useWindowSize();
    const scroll = useScrollPosition();

    if (!played && player.lottie && scroll > scrollPoint) {
        setPlayed(true);
        player.lottie.play();
    }

    return (
        <Player
            lottieRef={instance => {
                setPlayer({ lottie: instance }); 
                setScrollPoint(instance.wrapper.offsetTop - (size.height - instance.wrapper.offsetHeight * 0.4))
            }}
            autoplay={animationMode === "loop" || animationMode === "play"}
            loop={animationMode === "loop"}
            keepLastFrame={true}
            src={animation}
            style={{ width: "100%", height: "100%" }}
        />
            
    );
};

export default LottieAnimation;