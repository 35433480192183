import React from "react";
import classNames from "classnames";
import styles from "./image.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import { Button } from "../../../ui/Button";
import ImageLoader from "../../ImageLoader/ImageLoader";

import useWindowSize from "../../../../hooks/useWindowSize";

const Image = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();


  return (
    <section
      className={classNames(
        baseClass,
        styles.main,
        {
          [styles.align_right]: data.alingment === "right",
          [styles.align_left]: data.alingment === "left",
        },
        {
          [styles.display_side]: data.imageMode === "side",
          [styles.display_fill]: data.imageMode === "fill",
          [styles.display_expand]: data.imageMode === "expand",
        }
      )}
      style={moduleStyle}
    >
      
        {data.title ? <h1 className={styles.title}>{data.title}</h1> : ""}
        {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}
        <div  className={styles.body}>
        {data.body ? (
          <PortableText value={data.body} components={portableTextComponents}/>
        ) : (
          ""
        )}
        </div>
        { 
        data.buttonActive ?
          <div className={styles.button}>
            <Button data={data} />
          </div>
          : ""
        }
        
      <div className={styles.image}>
        <ImageLoader
          data={
            data.useMobileImage && data.mobileImage && sizes.width < 834
              ? data.mobileImage
              : data.image
          }
          crop={data.imageMode !== "expand"}
        />
      </div>
    </section>
  );
};

export default Image;
