import React, {useEffect, useState} from 'react';
import styles from "./landing.module.scss";
import useWindowSize from "../../../../hooks/useWindowSize";
import Column from "./Column"


const Layer = (props) => {

    const { objects, isBackground } = props;
    const sizes = useWindowSize();
    const [cols, setCols] = useState(null);
    const colNum = sizes.width < 415 ? 4 : 7;

    useEffect(() => {
        if (!cols) { 
            
            let colstemp = [];
            for (let i = 0; i < colNum; i++) {
                colstemp.push(i);
                if (isBackground) {
                    colstemp[i] += colNum;
                }
            }
            setCols(colstemp);
        }

    },[cols, setCols, sizes.width, colNum, isBackground]);

    if (!objects || !cols) { return null; }

    return (
        <div className={styles.layer}>
            {
                cols.map((col, i) => (
                    <Column objects={objects} startIndex={col} key={i} colNum={colNum}/>
                ))
            }
        </div>
    );
};

export default Layer;