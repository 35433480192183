import React, {useState, useCallback, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import styles from "./button.module.scss";
import classNames from "classnames";
import client from "../../../config/client";


const InternalLinkButton = (props) => {

    const {text, pageRef, label, styling, mainColor, secondaryColor, clickFunction, large} = props;
    const [linkData, setLinkData] = useState(null)

    const getPageFromRef = useCallback(async () => {
        const query = `*[_id == "${pageRef}"][0]{title, slug, _type}`;
    
        client
        .fetch(query)
        .then((data) => {
            setLinkData(data)
        })
        .catch(console.error);
    }, [setLinkData, pageRef])
    
    useEffect(() => {
        if (!linkData && pageRef) {
            getPageFromRef();
        }
    }, [linkData, getPageFromRef, pageRef])


    if (!linkData) return null;


    return (
        <NavLink 
            to={`/${linkData?._type === "caseStudy" ? "what-we-do/" : ""}${linkData?.slug?.current}`}
            onClick={clickFunction}
            aria-label={label}
            className={({ isActive }) =>
                classNames(styles.main, {
                    [styles.active]: isActive,
                    [styles.solid]: styling === "solid",
                    [styles.outline]: styling === "outline" || !styling,
                    [styles.large]: large
                })
            }
            
            style={{"--main-color" : mainColor, "--secondary-color" : secondaryColor}}
        >
            {linkData && !text ? linkData.title : text}
        </NavLink>
    );
    
    
};

export default InternalLinkButton;