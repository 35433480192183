import React from "react";
import ImageLoader from "../../ImageLoader/ImageLoader";
import styles from "./themeCards.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";

const Card = (props) => {
  const { data } = props;

  const cardStyle = {
    "--text-color": data.bodyColor ? data.bodyColor : "inherit",
    "--highlight-color": data.highlightColor ? data.highlightColor : "inherit",
  };

  return (
    <div
      className={styles.card}
      style={{ backgroundColor: data.backgroundColor }}
    >
      <div className={styles.image}>
        <ImageLoader data={data.image} crop={true}/>
      </div>
      <div className={styles.text} style={cardStyle}>
        {data.title ? <h1>{data.title}</h1> : ""}
        {data.body ? (
          <PortableText value={data.body} components={portableTextComponents} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Card;
