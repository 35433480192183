import React, { Fragment } from 'react';
import styles from "./footer.module.scss"
import { Link } from "react-router-dom"


const Footer = (props) => {

    const { data } = props;

    // console.log(data);

    return (
        <footer className={styles.main}>

                <div className={styles.column}>
                    <svg width="136" height="65" viewBox="0 0 136 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.6829 31.1141L10.5715 64.1514L0.311523 61.5534L8.42396 28.5173L2.26818 26.9583L4.36512 18.4144L10.522 19.9724L10.9439 18.2478C11.5253 15.3135 12.7765 12.5532 14.5998 10.1818C16.2271 8.33208 18.4894 7.16063 20.9391 6.89926C24.3832 6.59429 27.854 6.93955 31.1706 7.9171L29.0325 16.6297C26.558 16.0032 24.7729 15.9877 23.6773 16.5833C22.5818 17.1789 21.758 18.5997 21.206 20.8457L20.784 22.5714L27.4145 24.2495L25.3165 32.7934L18.6829 31.1141Z" fill="white"/>
                        <path d="M40.0953 56.3175C41.3827 57.3868 43.0186 57.9447 44.6911 57.8849C46.507 57.8599 48.2753 57.3004 49.7752 56.2763C51.5805 55.0228 53.0024 53.2927 53.8825 51.2788C55.0448 48.6403 55.601 45.7748 55.5101 42.8931V42.2096C54.7148 42.7566 53.8142 43.1316 52.8657 43.3108C51.8109 43.5372 50.4414 43.7633 48.7573 43.9891C46.9011 44.1873 45.0646 44.5401 43.2671 45.0439C41.8968 45.4601 40.6708 46.2524 39.7283 47.3307C38.6673 48.6914 38.1463 50.396 38.2652 52.1173C38.2334 52.9089 38.3816 53.6974 38.6986 54.4235C39.0156 55.1496 39.4931 55.7942 40.0953 56.3091M60.8379 24.5471C64.3349 27.1166 66.084 31.3656 66.0854 37.2943V63.3055H55.5101V56.7837C53.6006 59.1231 51.1791 60.9925 48.4324 62.2475C45.8119 63.4848 42.953 64.1353 40.0552 64.1536C36.8303 64.2608 33.6819 63.158 31.2287 61.0619C28.8695 59.0016 27.6902 56.0232 27.6909 52.1268C27.6909 48.4561 28.5854 45.6746 30.3743 43.7823C32.0716 41.9451 34.2828 40.6616 36.72 40.099C39.831 39.4355 42.9832 38.9827 46.1551 38.7435C48.5815 38.6079 50.9908 38.2549 53.3541 37.6887C53.9846 37.5663 54.5498 37.2205 54.9457 36.7147C55.3416 36.2089 55.5416 35.5772 55.509 34.9357C55.509 32.6208 54.8174 31.0119 53.4343 30.109C51.9369 29.1735 50.1962 28.7019 48.4314 28.7536C46.4374 28.7487 44.4758 29.2572 42.7355 30.2303C40.9444 31.219 39.8052 33.1813 39.3179 36.1171L29.3132 33.8304C29.5166 31.7852 30.2012 29.8173 31.3111 28.0876C32.421 26.3578 33.9246 24.9154 35.6989 23.8784C39.4168 21.7308 43.6479 20.6328 47.9409 20.7013C52.5431 20.5505 57.0692 21.9036 60.8336 24.5555" fill="white"/>
                        <path d="M135.726 28.6175C135.835 32.4992 134.737 37.07 132.431 42.3299L122.863 64.1503L113.202 59.7824L122.769 37.962C124.123 35.1837 125.074 32.227 125.595 29.1808C125.74 28.2661 125.562 27.3295 125.09 26.5323C124.619 25.7351 123.884 25.1274 123.013 24.8139C120.981 23.8948 118.569 24.3189 115.777 26.086C112.984 27.8531 109.89 32.6046 106.495 40.3405L100.491 54.0359L90.8299 49.668L100.397 27.8486C101.749 25.0696 102.701 22.1127 103.223 19.0664C103.368 18.152 103.189 17.2157 102.718 16.419C102.246 15.6222 101.512 15.0148 100.641 14.7016C98.6076 13.7826 96.1953 14.2044 93.4036 15.9673C90.6119 17.7302 87.5199 22.4824 84.1277 30.2239L78.1228 43.9194L68.4619 39.5515L85.4251 0.862671L95.086 5.23166L92.1684 11.8853C96.6689 8.11123 101.348 7.3219 106.206 9.51728C109.376 10.9497 111.453 12.8483 112.436 15.2132C113.438 17.7847 113.662 20.5941 113.08 23.2919C115.154 21.086 117.806 19.5065 120.733 18.733C123.374 18.151 126.134 18.468 128.574 19.6338C133.23 21.7434 135.613 24.737 135.721 28.6144" fill="white"/>
                    </svg>
                    
                </div>
                <div className={styles.cc}>
                        <p>{data.copyright}</p>
                    </div>
                {
                    data.lists.map((list, index) => (
                        <div className={styles.column} key={index}>
                            <h1>{list.title}</h1>
                            {
                                list.links.map((link, index) => (
                                    <Fragment key={index}>
                                    {
                                        link._type === "internal" ? (
                                            <Link
                                              to={`/${link.page.slug.current}`}
                                            >
                                                {link.page.title}
                                            </Link>
                                        ) : link._type === "external" ? (
                                            <a href={link.url} target="_blank" rel="noreferrer">
                                                {link.title}
                                            </a>
                                        ) : link._type === "emailLink" ? (
                                            <a href={`mailto:${link.email}`} target="_blank" rel="noreferrer">
                                                {link.title}
                                            </a>
                                        ) : (
                                        ""
                                        ) 
                                    }
                                    </Fragment>
                                ))
                            }
                        </div>
                    ))
                }
                   
        </footer>
    );
};

export default Footer;