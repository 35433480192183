import React from "react"
import styles from "./animationCards.module.scss";
import LottieAnimation from "../../LottieAnimation/LottieAnimation";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import useWindowSize from "../../../../hooks/useWindowSize";

const Card = (props) => {
    const { data } = props
    const sizes = useWindowSize();
    
    const cardStyle = {
        backgroundColor: data.backgroundColor ? data.backgroundColor : "inherit", 
        "--text-color": data.bodyColor ? data.bodyColor : "inherit",
        "--highlight-color": data.highlightColor ? data.highlightColor : "inherit",
    }

    return (
        <div className={styles.card} style={cardStyle}>
            {data.title ? <h2 className={styles.title}>{data.title}</h2> : ""}
            {data.body ? (
                <div className={styles.body}>
                    <PortableText value={data.body} components={portableTextComponents} className={styles.body}/>
                </div>
            ) : (
            ""
            )}
            <div className={styles.animation}>
                <LottieAnimation
                animation={
                    data.useMobileAnimation && data.mobileAnimation && sizes.width < 415
                    ? data.mobileAnimation
                    : data.animation
                }
                animationMode={data.animationMode}
                />
            </div>
        </div>
    )
}

export default Card