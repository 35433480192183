import React from 'react';
import styles from "./loading.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";

const Loading = () => {

    return (
        <div className={styles.main}>
            <Player
            autoplay={true}
            loop={true}
            keepLastFrame={true}
            src={"/animations/loading.json"}
            style={{ width: "100px", height: "100px" }}
        />
        </div>
    );
};

export default Loading;