import React, { useState } from "react";
import { Animation, Image, Video } from "../";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import styles from "./carousel.module.scss";

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Carousel = (props) => {
  const { data, baseClass } = props;
  const [activeSlide, setActiveSlide] = useState([0, 1]);

  const variants = {
    enter: (direction) => ({
      opacity: 1,
      x: `${direction * 100}%`,
      position: "absolute",
    }),
    center: {
      opacity: 1,
      x: 0,
      position: "absolute",
    },
    exit: (direction) => ({
      opacity: 1,
      x: `${direction * -100}%`,
      position: "absolute",
    }),
  };

  const nextSlide = () => {
    if (activeSlide[0] < data.slides.length - 1) {
      setActiveSlide([activeSlide[0] + 1, 1]);
    } else {
      setActiveSlide([0, 1]);
    }
  };

  const prevSlide = () => {
    if (activeSlide[0] > 0) {
      setActiveSlide([activeSlide[0] - 1, -1]);
    } else {
      setActiveSlide([data.slides.length - 1, -1]);
    }
  };

  return (
    <motion.section 
      className={classNames(baseClass, styles.main)} 
      layout
      style={{
        "--ui-color": data.uiColor ? data.uiColor : "inherit",
        "--ui-highlight-color": data.highlightColor ? data.highlightColor : "inherit"
      }}
    >
      <AnimatePresence initial={false} custom={activeSlide[1]}>
        <motion.div
          style={{ position: "relative", top: "0", left: "0", width: "100%", height: "100% "}}
          key={activeSlide[0]}
          custom={activeSlide[1]}
          initial="enter"
          animate="center"
          exit="exit"
          variants={variants}
          transition={{
            duration: 0.3,
            ease: "easeOut",
            type: "tween",
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              nextSlide();
            } else if (swipe > swipeConfidenceThreshold) {
              prevSlide();
            }
          }}
        >
          {data.slides[activeSlide[0]]._type === "video_module" ? (
            <Video
              moduleStyle={{
                "--background-color": data.slides[activeSlide[0]].backgroundColor ? data.slides[activeSlide[0]].backgroundColor : "inherit", 
                "--text-color": data.slides[activeSlide[0]].bodyColor ? data.slides[activeSlide[0]].bodyColor : "inherit",
                "--highlight-color": data.slides[activeSlide[0]].highlightColor ? data.slides[activeSlide[0]].highlightColor : "inherit",
                marginBottom: 0,
                paddingBottom: "70px",
                height: "100%"
              }}
              baseClass={baseClass}
              data={data.slides[activeSlide[0]]}
            />
          ) : data.slides[activeSlide[0]]._type === "image_module" ? (
            <Image
            moduleStyle={{
              "--background-color": data.slides[activeSlide[0]].backgroundColor ? data.slides[activeSlide[0]].backgroundColor : "inherit", 
              "--text-color": data.slides[activeSlide[0]].bodyColor ? data.slides[activeSlide[0]].bodyColor : "inherit",
              "--highlight-color": data.slides[activeSlide[0]].highlightColor ? data.slides[activeSlide[0]].highlightColor : "inherit",
              marginBottom: 0,
              paddingBottom: "70px",
              height: "100%"
            }}
              baseClass={baseClass}
              data={data.slides[activeSlide[0]]}
            />
          ) : data.slides[activeSlide[0]]._type === "animation_module" ? (
            <Animation
            moduleStyle={{
              "--background-color": data.slides[activeSlide[0]].backgroundColor ? data.slides[activeSlide[0]].backgroundColor : "inherit", 
              "--text-color": data.slides[activeSlide[0]].bodyColor ? data.slides[activeSlide[0]].bodyColor : "inherit",
              "--highlight-color": data.slides[activeSlide[0]].highlightColor ? data.slides[activeSlide[0]].highlightColor : "inherit",
              marginBottom: 0,
              paddingBottom: "70px",
              height: "100%"
            }}
              baseClass={baseClass}
              data={data.slides[activeSlide[0]]}
            />
          ) : (
            ""
          )}
        </motion.div>
      </AnimatePresence>
      <div className={styles.position}>
        <button
          onClick={prevSlide}
          className={classNames(styles.button, styles.prev)}
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 20L1.5 10.5L11 1"  />
          </svg>
        </button>
        {data.slides.map((slide, index) => (
          <motion.div
            className={classNames(styles.ellipse, {
              [styles.active]: activeSlide[0] === index,
            })}
            key={index}
            animate={{
              x: -(activeSlide[0] - index) * 7,
              scale: Math.max(1 - Math.abs(activeSlide[0] - index) * 0.2, 0),
            }}
            onClick={() => {
              setActiveSlide([index, activeSlide[0] < index ? 1 : -1]);
            }}
          ></motion.div>
        ))}
        <button
          onClick={nextSlide}
          className={classNames(styles.button, styles.next)}
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L10.5 10.5L1 20" />
          </svg>
        </button>
      </div>
    </motion.section>
  );
};

export default Carousel;
