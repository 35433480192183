import React from "react";
import classNames from "classnames";
import styles from "./logos.module.scss";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import { Button } from "../../../ui/Button";
import ImageLoader from "../../ImageLoader/ImageLoader";
import useWindowSize from "../../../../hooks/useWindowSize";

const Logos = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();
  
  return (
    <section
      className={classNames(baseClass, styles.main, {
        [styles.full]: data.layout === "full",
        [styles.display_fill]: data.showImage && data.image,
      })}
      style={moduleStyle}
    >
      {data.showImage && data.image ?  
      <div className={styles.image}>
        <ImageLoader
          data={data.useMobileImage && data.mobileImage && sizes.width < 834 ? data.mobileImage : data.image}
          crop 
          width={sizes.width}
          aspectRatio={16 / 9}
        />
      </div> : ""}
      {data.title ? <h1 className={styles.title}>{data.title}</h1> : ""}
     
        {data.heading ? <h2 className={styles.heading}>{data.heading}</h2> : ""}
        <div className={styles.body}>
          {data.body ? (
            <PortableText
              value={data.body}
              components={portableTextComponents}
            />
          ) : (
            ""
          )}
        </div>
        {data.buttonActive ? (
          <div className={styles.button}>
            <Button data={data} />
          </div>
        ) : (
          ""
        )}
   
      <div className={styles.logos}>
        {data.logos.map((logo, index) => (
          <div className={styles.logo} key={index}>
            <ImageLoader
              data={logo.image}
              width={300}
              aspectRatio={null}
            />
          </div>
        ))}
      </div>
      
    </section>
  );
};

export default Logos;
