import React from "react";
import classNames from "classnames";
import styles from "./team.module.scss";
import ImageLoader from "../../ImageLoader/ImageLoader";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import useWindowSize from "../../../../hooks/useWindowSize";

const Team = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();

  console.log(data);

  return (
    <section
      className={classNames(baseClass, styles.main)}
      style={moduleStyle}
    >
        <div className={styles.text}>
            {data.title ? <h1>{data.title}</h1> : ""}
            {data.heading ? <h2>{data.heading}</h2> : ""}
            {data.body ? (
            <PortableText value={data.body} components={portableTextComponents} />
            ) : (
                ""
            )}
        </div>
       
            {data.team.map((member, index) => (
                <div className={styles.member} key={index}>
                    {member.potrait && member.overlay ? 
                        <div className={styles.potrait}>
                            
                            <ImageLoader 
                                data={member.potrait}
                                crop={false}
                                width={sizes.width < 834 ? sizes.width : sizes.width / 3}
                            />
                            <ImageLoader
                                data={member.overlay}
                                crop={false}
                                width={sizes.width < 834 ? sizes.width : sizes.width / 3}
                            />
                        </div>
                    : ""}
                    {member.memberName ? <h4>{member.memberName}</h4> : ""}
                    {member.title ? <p>{member.title}</p> : ""}
                </div>
            ))}
      
    </section>
  );
};

export default Team;

