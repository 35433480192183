import React, { useState } from 'react';
import styles from "./navigation.module.scss";
import { motion, AnimatePresence } from "framer-motion"
import { FunctionButton, InternalLinkButton, ExternalLinkButton } from '../Button';
import Logo from './Logo';

// Currently unused functionality exists to change the colors of the nav bar on a page basis
const NavBar = (props) => {
    let {mainColor, secondaryColor, linksData} = props;
    const [navExpanded, setNavExpanded] = useState(false);

    mainColor = (typeof mainColor !== 'undefined') ? mainColor : "#ffffff";
    secondaryColor = (typeof secondaryColor !== 'undefined') ? secondaryColor :  "#FF4927";

    const toggleNavExpanded = () => {
        setNavExpanded(!navExpanded);
    }

    if (linksData) {
        return (
            <div role="banner" className={styles.main} style={{"--main-color": mainColor, "--secondary-color": secondaryColor}}>
                <Logo 
                    setNavExpanded={setNavExpanded}
                    navExpanded={navExpanded}
                    mainColor={ mainColor}
                    secondaryColor={secondaryColor }
                />
                <FunctionButton 
                    clickFunction={toggleNavExpanded} 
                    text={navExpanded ? "Close" : "Menu"}
                    label="Toggle navigation"
                    styling="outline"
                    mainColor={navExpanded ? mainColor : secondaryColor}
                    secondaryColor={navExpanded ? secondaryColor : mainColor}
                />
                <AnimatePresence exitBeforeEnter>
                    {navExpanded && 
                        <motion.nav 
                            initial={{x : "100%"}}
                            animate={{x : 0}}
                            exit={{x : "100%"}}
                            transition={{duration: 0.3, easing: "easeinout"}}
                            className={styles.nav}
                        >
                          <div className={styles.buttons}>
                            {
                                linksData.map((link, index) => (
                                link._type === "internal" ?
                                   <InternalLinkButton clickFunction={toggleNavExpanded} pageRef={link.page._ref} styling="outline" mainColor={mainColor} secondaryColor={secondaryColor} key={index} large={true}/>
                                :  <ExternalLinkButton clickFunction={toggleNavExpanded} url={link.url} text={link.title} styling="outline" mainColor={mainColor} secondaryColor={secondaryColor} key={index} large={true}/>
                                    
                                ))
                            }

                        </div>
                        </motion.nav>
                    }
                </AnimatePresence>
            </div>
        );
    } 

    else {
       return( <div role="banner" className={styles.main} style={{"--main-color": mainColor, "--secondary-color": secondaryColor}}>
        <Logo 
            setNavExpanded={setNavExpanded}
            navExpanded={navExpanded}
            mainColor={ mainColor}
            secondaryColor={secondaryColor }
        />
        
    </div>)
    }
    
};

export default NavBar;