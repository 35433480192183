import React from "react";
import { InternalLinkButton, ExternalLinkButton, EmailButton } from "./";

const Button = (props) => {
    const {data, large} = props;

    if (!data.buttonActive) { return null}

    switch(data.linkType) {
        case "internal":
            return <InternalLinkButton
                pageRef={data.page._ref}
                styling="outline"
                text={data.buttonText}
                mainColor={data.highlightColor}
                secondaryColor={data.backgroundColor}
                large={large}
                />;
        case "external":
            return <ExternalLinkButton
                url={data.url}
                styling="outline"
                text={data.buttonText}
                mainColor={data.highlightColor}
                secondaryColor={data.backgroundColor}
                large={large}
                />;
        case "email":
            return <EmailButton
                email={data.email}
                styling="outline"
                text={data.buttonText}
                mainColor={data.highlightColor}
                secondaryColor={data.backgroundColor}
                large={large}
                />;
        default:
            return null;
    }

}

export default Button