import React from "react";
import classNames from "classnames";
import styles from "./threeColumns.module.scss";
import LottieAnimation from "../../LottieAnimation/LottieAnimation";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";

const ThreeColumns = (props) => {
  const { baseClass, moduleStyle, data } = props;

  return (
    <section
      className={classNames(baseClass, styles.main)}
      style={moduleStyle}
    >
        {
            data.columns.map((column, index) => (
                <div className={styles.column} key={index}>
                    <div className={styles.text}>
                    {column.title ? <h1>{column.title}</h1> : ""}
                    {column.body ? (
                    <PortableText value={column.body} components={portableTextComponents} />
                    ) : (
                    ""
                    )}
                    </div>
                    <div className={styles.animation}>
                        <LottieAnimation
                        animation={
                            column.animation
                        }
                        animationMode={column.animationMode}
                        />
                    </div>
                </div>
            ))
        }
    </section>
  );
};

export default ThreeColumns;
