import React from "react";
import classNames from "classnames";
import styles from "./newsletter.module.scss";
import ImageLoader from "../../ImageLoader/ImageLoader";
import { ExternalLinkButton } from "../../../ui/Button";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../../../../config/portableTextComponents";
import useWindowSize from "../../../../hooks/useWindowSize";

const Newsletter = (props) => {
  const { baseClass, moduleStyle, data } = props;
  const sizes = useWindowSize();

   console.log(data);

  return (
    <section
      className={classNames(baseClass, styles.main)}
      style={moduleStyle}
    >
        <div className={styles.text}>
            
            {data.heading ? <h2>{data.heading}</h2> : ""}
            
        </div>
        <div
            className={styles.posts}
        >
            {
                data.posts.map((post, index) => (
                    <div className={styles.post} key={index}>
                        <div className={styles.postImage}>
                        <ImageLoader
                            data={post.cover}
                            crop = {true}
                            width={sizes.width < 834 ? sizes.width : sizes.width / 4}
                            aspectRatio={4/3}
                        />
                        </div>
                        <h4>{post.title}</h4>
                        <PortableText value={post.intro} components={portableTextComponents} />
                        <ExternalLinkButton 
                            url={post.link}
                            styling="outline"
                            text={post.buttonText}
                            mainColor={data.highlightColor}
                            secondaryColor={data.backgroundColor}
                        />
                    </div>
                ))
            }
            <div className={styles.post}>
                <div className={styles.postImage}>
                    <ExternalLinkButton 
                        url={data.link}
                        styling="outline"
                        text={data.linkText}
                        mainColor={data.backgroundColor}
                        secondaryColor={data.highlightColor}
                    />
                </div>
                
            </div>

            {/* {data.team.map((member, index) => (
                <div className={styles.member} key={index}>
                    {member.potrait && member.overlay ? 
                        <div className={styles.potrait}>
                            
                            <ImageLoader 
                                data={member.potrait}
                                crop={false}
                                width={sizes.width < 834 ? sizes.width : sizes.width / 3}
                            />
                            <ImageLoader
                                data={member.overlay}
                                crop={false}
                                width={sizes.width < 834 ? sizes.width : sizes.width / 3}
                            />
                        </div>
                    : ""}
                    {member.memberName ? <h4>{member.memberName}</h4> : ""}
                    {member.title ? <p>{member.title}</p> : ""}
                </div>
            ))} */}
        </div>
    </section>
  );
};

export default Newsletter;

